/* eslint-disable no-console */
import { useEffect, useState } from "react";

export const useIsExternalLink = (href) => {
  const [isExternalLink, setIsExternalLink] = useState(false);
  const [shouldNofollow, setShouldNofollow] = useState(true);

  useEffect(() => {
    const checkExternalLink = () => {
      try {
        const urlObj = new URL(href);
        const { hostname } = urlObj;
        const currentHost = window.location.host;

        const topLevelDomain = currentHost.split(".").slice(-2).join(".");
        const isDifferentTopLevelDomain =
          topLevelDomain !== hostname.split(".").slice(-2).join(".");

        setIsExternalLink(
          hostname !== currentHost || isDifferentTopLevelDomain
        );

        switch (true) {
          case currentHost.includes(".tobii.com"):
            setShouldNofollow(false);
            break;

          case currentHost === "www.tobii.cn" && isExternalLink:
          case currentHost === "www.tobii.com" &&
            (isExternalLink || hostname.includes(".tobii.com")):
            setShouldNofollow(true);
            break;

          default:
            setShouldNofollow(false);
        }
      } catch (err) {
        setIsExternalLink(false);
        setShouldNofollow(true);
      }
    };

    checkExternalLink();
  }, [isExternalLink, href]);

  return { isExternalLink, shouldNofollow };
};
